import { type TenantEnum } from '@jsmdg/tenancy';
import { CheckoutFooterLinkMessageId } from '../../shared/enums/checkoutFooterLinkMessageIds';
import { FooterLinkTrackingId } from '../../shared/enums/footerLinkTrackingIds';
import { type CheckoutFooterLink } from '../../shared/types/tenantConfig';
import { type TenantSharedData } from '../../shared/types/tenantSharedData';
import { legalNoticeUrls, termsOfUseUrls } from './commonUrls';

type Args = {
    tenant: TenantEnum;
    isMydays: boolean;
    sharedData: TenantSharedData;
};

export function getCheckoutLinks({ isMydays, sharedData, tenant }: Args): CheckoutFooterLink[] {
    if (isMydays)
        return [
            {
                url: sharedData.urls.termAndConditions,
                messageId: CheckoutFooterLinkMessageId.TermsAndConditions,
                trackingId: FooterLinkTrackingId.TermsAndConditions,
            },
            {
                url: '#uc-corner-modal-show',
                messageId: CheckoutFooterLinkMessageId.CookieSettings,
                trackingId: FooterLinkTrackingId.CookieSettings,
            },
            {
                url: sharedData.urls.privacyPolicy,
                messageId: CheckoutFooterLinkMessageId.Privacy,
                trackingId: FooterLinkTrackingId.Privacy,
            },
            {
                url: termsOfUseUrls[tenant],
                messageId: CheckoutFooterLinkMessageId.TermsOfUse,
                trackingId: FooterLinkTrackingId.TermsOfUse,
            },
            {
                url: '/online-marktplatz/l/rt9af',
                messageId: CheckoutFooterLinkMessageId.OnlineMarketplace,
                trackingId: FooterLinkTrackingId.OnlineMarketplace,
            },
            {
                url: '/Compliance/l/2ught',
                messageId: CheckoutFooterLinkMessageId.Compliance,
                trackingId: FooterLinkTrackingId.Compliance,
            },
            {
                url: legalNoticeUrls[tenant],
                messageId: CheckoutFooterLinkMessageId.LegalNotice,
                trackingId: FooterLinkTrackingId.LegalNotice,
            },
        ];

    return [
        {
            url: sharedData.urls.termAndConditions,
            messageId: CheckoutFooterLinkMessageId.TermsAndConditions,
            trackingId: FooterLinkTrackingId.TermsAndConditions,
        },
        {
            url: '#uc-corner-modal-show',
            messageId: CheckoutFooterLinkMessageId.CookieSettings,
            trackingId: FooterLinkTrackingId.CookieSettings,
        },
        {
            url: sharedData.urls.privacyPolicy,
            messageId: CheckoutFooterLinkMessageId.Privacy,
            trackingId: FooterLinkTrackingId.Privacy,
        },
        {
            url: termsOfUseUrls[tenant],
            messageId: CheckoutFooterLinkMessageId.TermsOfUse,
            trackingId: FooterLinkTrackingId.TermsOfUse,
        },
        {
            url: 'Online-Marktplatz/JS_Online-Marktplatz/l/y6wix',
            messageId: CheckoutFooterLinkMessageId.OnlineMarketplace,
            trackingId: FooterLinkTrackingId.OnlineMarketplace,
        },
        {
            url: '/compliance/l/aqzwr',
            messageId: CheckoutFooterLinkMessageId.Compliance,
            trackingId: FooterLinkTrackingId.Compliance,
        },
        {
            url: legalNoticeUrls[tenant],
            messageId: CheckoutFooterLinkMessageId.LegalNotice,
            trackingId: FooterLinkTrackingId.LegalNotice,
        },
    ];
}
