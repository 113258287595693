import { SocialIconName } from '../../shared/enums/socialIconName';
import { type SocialMediaIconDetail } from '../../shared/types/tenantConfig';
import { type TenantSharedData } from '../../shared/types/tenantSharedData';

export function getSocialMedia(sharedData: TenantSharedData): SocialMediaIconDetail[] {
    return [
        {
            url: sharedData.urls.socialMedia.facebook,
            iconName: SocialIconName.FaceBook,
            title: 'Facebook',
        },
        {
            url: sharedData.urls.socialMedia.instagram,
            iconName: SocialIconName.Instagram,
            title: 'Instagram',
        },
        {
            url: sharedData.urls.socialMedia.pinterest,
            iconName: SocialIconName.Pinterest,
            title: 'Pinterest',
        },
        {
            url: sharedData.urls.socialMedia.youtube,
            iconName: SocialIconName.Youtube,
            title: 'Youtube',
        },
    ];
}
