import { memo, useCallback, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import classNames from 'classnames';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { Link, LinkVariant } from '@jsmdg/yoshi';
import { trackTrustpilot } from '../../helper/trackingFooter';
import { type FooterContext } from '../../types';
import styles from './Trustpilot.module.scss';

type TrustPilotProps = {
    readonly locale: string;
};
const messages = defineMessages({
    trustpilotWidgetTitle: { defaultMessage: 'Trustpilot' },
});
const Trustpilot = memo(({ locale }: TrustPilotProps): JSX.Element => {
    const intl = useIntl();
    const { tenantConfig } = useFragmentContext<FooterContext>();
    const { trustpilotSettings } = tenantConfig;
    const [iframeMouseOver, setIframeMouseOver] = useState<boolean>(false);
    const handleWindowBlur = (): void => {
        if (!iframeMouseOver) {
            trackTrustpilot(trustpilotSettings.url);
            window.removeEventListener('blur', handleWindowBlur);
        }
    };

    const handleOnMouseOver = useCallback((): void => {
        setIframeMouseOver(true);
        window.addEventListener('blur', handleWindowBlur);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOnMouseOut = useCallback((): void => {
        window.removeEventListener('blur', handleWindowBlur);
        setIframeMouseOver(false);
        window.focus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={classNames(styles.trustpilot, 'mt-2x w-100 py-2x py-lg-3x text-center')}>
            <div
                className="trustpilot-widget"
                data-locale={locale}
                data-template-id={trustpilotSettings.dataTemplateId}
                data-businessunit-id={trustpilotSettings.dataBusinessUnitId}
                data-style-height="100%"
                data-style-width="100%"
                data-theme="light"
                onMouseOver={handleOnMouseOver}
                onMouseOut={handleOnMouseOut}
                onFocus={() => window.focus()}
                onBlur={() => window.blur()}
            >
                <Link variant={LinkVariant.GreyQuiet} href={trustpilotSettings.url} target="_blank">
                    {intl.formatMessage(messages.trustpilotWidgetTitle)}
                </Link>
            </div>
        </div>
    );
});
Trustpilot.displayName = 'Trustpilot';
export { Trustpilot };
