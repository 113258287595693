import { type TenantEnum } from '@jsmdg/tenancy';
import { FooterLinkMessageId } from '../../shared/enums/footerLinkMessageIds';
import { FooterLinkTrackingId } from '../../shared/enums/footerLinkTrackingIds';
import { type FooterLink } from '../../shared/types/tenantConfig';
import { type TenantSharedData } from '../../shared/types/tenantSharedData';
import { magazinUrls, paymentUrls, shippingUrls } from './commonUrls';

type Args = {
    isDE: boolean;
    tenant: TenantEnum;
    isMydays: boolean;
    sharedData: TenantSharedData;
};

export function getFooterFirstLineLinks({
    isDE,
    isMydays,
    tenant,
    sharedData,
}: Args): FooterLink[] {
    if (isMydays) {
        return [
            {
                url: sharedData.urls.faq,
                messageId: FooterLinkMessageId.Faq,
                trackingId: FooterLinkTrackingId.Faq,
            },
            {
                url: sharedData.urls.contact,
                messageId: FooterLinkMessageId.Contact,
                trackingId: FooterLinkTrackingId.Contact,
            },
            {
                url: paymentUrls[tenant],
                messageId: FooterLinkMessageId.PaymentMethods,
                trackingId: FooterLinkTrackingId.PaymentMethods,
            },
            {
                url: 'https://unternehmen.mydays.de/ueber-uns/',
                messageId: FooterLinkMessageId.AboutUs,
                trackingId: FooterLinkTrackingId.AboutUs,
            },
            {
                url: magazinUrls[tenant],
                messageId: FooterLinkMessageId.Magazin,
                trackingId: FooterLinkTrackingId.Magazin,
            },
            {
                url: sharedData.urls.career,
                messageId: FooterLinkMessageId.JobsAndCareers,
                trackingId: FooterLinkTrackingId.Jobs,
            },
            {
                url: '/kampagnen/affiliate-partner/l/38o1x',
                messageId: FooterLinkMessageId.AffiliateProgram,
                trackingId: FooterLinkTrackingId.AffiliateProgram,
            },
            ...(isDE
                ? [
                      {
                          url: 'https://www.trustedshops.de/bewertung/info_XF19E52605958CC17000B9656BC0326F7.html',
                          messageId: FooterLinkMessageId.TrustedShops,
                          trackingId: FooterLinkTrackingId.TrustedShops,
                          iconAltText: 'Trustedshops Bewertungen für mydays.de',
                      },
                      {
                          url: sharedData.urls.payback,
                          messageId: FooterLinkMessageId.Payback,
                          trackingId: FooterLinkTrackingId.Payback,
                          iconAltText: 'PAYBACK',
                      },
                  ]
                : []),
            {
                url: shippingUrls[tenant],
                messageId: FooterLinkMessageId.Shipping,
                trackingId: FooterLinkTrackingId.Shipping,
                iconAltText: 'DPD',
            },
        ];
    }

    return [
        {
            url: sharedData.urls.faq,
            messageId: FooterLinkMessageId.Faq,
            trackingId: FooterLinkTrackingId.Faq,
        },
        {
            url: sharedData.urls.contact,
            messageId: FooterLinkMessageId.Contact,
            trackingId: FooterLinkTrackingId.Contact,
        },
        {
            url: paymentUrls[tenant],
            messageId: FooterLinkMessageId.PaymentMethods,
            trackingId: FooterLinkTrackingId.PaymentMethods,
        },
        {
            url: '/unternehmen/ueber-uns/l/7pk9h?selectedTab=unternehmen',
            messageId: FooterLinkMessageId.AboutUs,
            trackingId: FooterLinkTrackingId.AboutUs,
        },
        {
            url: magazinUrls[tenant],
            messageId: FooterLinkMessageId.Magazin,
            trackingId: FooterLinkTrackingId.Magazin,
        },
        {
            url: sharedData.urls.career,
            messageId: FooterLinkMessageId.Jobs,
            trackingId: FooterLinkTrackingId.Jobs,
        },
        {
            url: '/partnerprogramm/affiliate-partner/l/dapo7',
            messageId: FooterLinkMessageId.AffiliateProgram,
            trackingId: FooterLinkTrackingId.AffiliateProgram,
        },
        ...(isDE
            ? [
                  {
                      url: sharedData.urls.payback,
                      messageId: FooterLinkMessageId.Payback,
                      trackingId: FooterLinkTrackingId.Payback,
                      iconAltText: 'PAYBACK',
                  },
              ]
            : []),
        {
            url: shippingUrls[tenant],
            messageId: FooterLinkMessageId.Shipping,
            trackingId: FooterLinkTrackingId.Shipping,
            iconAltText: 'DPD',
        },
    ];
}
