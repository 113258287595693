export enum FooterLinkTrackingId {
    Agb = 'AGB',
    Faq = 'FAQ',
    Contact = 'Kontakt',
    PaymentMethods = 'Zahlungsarten',
    AboutUs = 'Ueber uns',
    Jobs = 'Jobs',
    AffiliateProgram = 'Partnerprogramm',
    Payback = 'Payback',
    Shipping = 'Versand und Lieferung',
    Press = 'Presse',
    TermsAndConditions = 'AGB',
    CookieSettings = 'Cookie Einstellungen',
    Privacy = 'Datenschutz',
    TermsOfUse = 'Nutzungsbedingungen',
    OnlineMarketplace = 'Online-Marktplatz',
    LegalNotice = 'Impressum',
    Compliance = 'Compliance',
    TrustedShops = 'Trusted Shops',
    Magazin = 'Magazin',
}
