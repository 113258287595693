import { useEffect, useState } from 'react';

function useCheckUrl(pathname: string): boolean {
    const [urlType, setUrlType] = useState(false);

    useEffect(() => {
        if (pathname.endsWith('/cart')) {
            setUrlType(true);
        }
    }, [pathname]);

    return urlType;
}

export { useCheckUrl };
