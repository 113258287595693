import classNames from 'classnames';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { TenantEnum } from '@jsmdg/tenancy';
import {
    DPDLogoSrc,
    Image,
    Link,
    LinkVariant,
    PaybackLogoSrc,
    TrustedShopsLogoSrc,
} from '@jsmdg/yoshi';
import { FooterLinkTrackingId } from '../../../../shared/enums/footerLinkTrackingIds';
import { trackTextlinkClick } from '../../../helper/trackingFooter';
import { type FooterContext } from '../../../types';
import styles from './FooterLinkItem.module.scss';

const handleCookieSettingsClick = async (
    trackingId: string,
    message: string,
    url: string,
): Promise<void> => {
    const isUsercentricsV2Loaded = window.UC_UI?.isInitialized();

    if (isUsercentricsV2Loaded) {
        // See https://docs.usercentrics.com/#/cmp-v2-ui-api?id=showsecondlayer
        window.UC_UI?.showSecondLayer();
    }

    await window.yieldToMainThread();
    trackTextlinkClick(trackingId, message, url);
};

type Props = {
    readonly url: string;
    readonly iconAltText?: string;
    readonly trackingId: string;
    readonly message: string;
    readonly className?: string;
};

interface IconUrl {
    leftIconURL: string;
    rightIconURL: string;
    iconWidth: number;
    iconHeight: number;
}

const iconDetails: Record<string, IconUrl> = {
    [FooterLinkTrackingId.TrustedShops]: {
        leftIconURL: TrustedShopsLogoSrc,
        rightIconURL: '',
        iconWidth: 20,
        iconHeight: 20,
    },
    [FooterLinkTrackingId.Payback]: {
        leftIconURL: PaybackLogoSrc,
        rightIconURL: '',
        iconWidth: 18,
        iconHeight: 18,
    },
    [FooterLinkTrackingId.Shipping]: {
        leftIconURL: '',
        rightIconURL: DPDLogoSrc,
        iconWidth: 43,
        iconHeight: 18,
    },
};

const getIconUrl = (trackingId: string): IconUrl => {
    const details = iconDetails[trackingId] || {};
    const { iconHeight = 24, iconWidth = 24, leftIconURL = '', rightIconURL = '' } = details;

    return { leftIconURL, rightIconURL, iconWidth, iconHeight };
};

export const FooterLinkItem = ({
    className,
    iconAltText,
    message,
    trackingId,
    url,
}: Props): JSX.Element | null => {
    const { tenant, usercentricsEnabled } = useFragmentContext<FooterContext>();

    if (trackingId === FooterLinkTrackingId.CookieSettings && !usercentricsEnabled) return null;

    const showTrustedShops =
        trackingId === FooterLinkTrackingId.TrustedShops && tenant === TenantEnum.MD_DE;

    const handleClick = async (e: React.MouseEvent): Promise<void> => {
        e.preventDefault();
        if (trackingId === FooterLinkTrackingId.CookieSettings) {
            await handleCookieSettingsClick(trackingId, message, url);
        } else {
            trackTextlinkClick(trackingId, message, url);
        }

        window.location.href = url;
    };

    const { iconHeight, iconWidth, leftIconURL, rightIconURL } = getIconUrl(trackingId);

    return (
        <Link
            variant={LinkVariant.GreyQuiet}
            href={url}
            className={classNames(
                styles.footerLink,
                'mb-2x align-items-center',
                { 'd-md-none mb-2x': showTrustedShops },
                className,
            )}
            key={trackingId}
            onClick={handleClick}
            iconLeft={
                leftIconURL && (
                    <Image
                        width={iconWidth}
                        height={iconHeight}
                        className="mr-0-5x"
                        src={leftIconURL}
                        alt={iconAltText || message}
                        loading="lazy"
                    />
                )
            }
            iconRight={
                rightIconURL && (
                    <Image
                        width={iconWidth}
                        height={iconHeight}
                        className="ml-0-5x"
                        src={rightIconURL}
                        alt={iconAltText || 'DPL Logo'}
                        loading="lazy"
                    />
                )
            }
        >
            {message}
        </Link>
    );
};
