import { defineMessages, useIntl } from 'react-intl';
import classNames from 'classnames';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { type FooterContext } from '../../types';
import { HelpLink } from './HelpLink/HelpLink';
import styles from './LinksBlock.module.scss';

const messages = defineMessages({
    searchTermsTitle: { defaultMessage: 'Häufigste Suchbegriffe' },
    onlineHelpTitle: { defaultMessage: 'Online Hilfe' },
    adventure: { defaultMessage: 'Abenteuer' },
    arena: {
        defaultMessage: 'Erlebnisse Jochen Schweizer Arena',
    },
    candleDinner: { defaultMessage: 'Candle Light Dinner' },
    darkDinner: { defaultMessage: 'Dinner in the Dark' },
    extraordinary: { defaultMessage: 'Außergewöhnliche Geschenke' },
    fancy: { defaultMessage: 'Ausgefallene Geschenke' },
    friend: { defaultMessage: 'Geschenk Freundin' },
    gliding: { defaultMessage: 'Segelfliegen' },
    ideas: { defaultMessage: 'Geschenkideen' },
    men: { defaultMessage: 'Männergeschenke' },
    photoShoot: { defaultMessage: 'Fotoshooting' },
    quadTour: { defaultMessage: 'Quad Tour' },
    skydiving: { defaultMessage: 'Fallschirmspringen' },
    special: { defaultMessage: 'Besondere Geschenke' },
    tips: { defaultMessage: 'Tipps gegen Langeweile' },
    voucher: { defaultMessage: 'Geschenkgutschein' },
    giftsForCouples: { defaultMessage: 'Geschenke für Paare' },
    giftsForMen: { defaultMessage: 'Geschenke für Männer' },
    giftsForWomen: { defaultMessage: 'Geschenke für Frauen' },
    shortBreak: { defaultMessage: 'Kurzurlaub' },
    wellness: { defaultMessage: 'Wellness' },
    wellnessVacation: { defaultMessage: 'Wellnesurlaub' },
    wellnessWeekend: { defaultMessage: 'Wellness Wochende' },
    ballonRide: { defaultMessage: 'Ballonfahrt' },
    wellnessHotel: { defaultMessage: 'Wellnesshotel' },
    anniversaryGifts: { defaultMessage: 'Geschenke zum Jahrestag' },
    valueVouchers: { defaultMessage: 'Wertgutscheine' },
    romanticWeekend: { defaultMessage: 'Romantisches Wochenende' },
    alpacaHike: { defaultMessage: 'Alpaka Wanderung' },
    christmasPresents: { defaultMessage: 'Weihnachtsgeschenke' },
    wineTasting: { defaultMessage: 'Weinprobe' },
    exceptionalHotels: { defaultMessage: 'Außergewöhnliche Hotels' },
    drivingQuad: { defaultMessage: 'Quadfahren' },
    discount: { defaultMessage: 'Wie verwende ich Rabatt- und Gewinnspiel-Codes?' },
    redeem: { defaultMessage: 'Wie löse ich Gutscheine ein?' },
    exchange: { defaultMessage: 'Wie tausche ich Gutscheine um?' },
});

const LinksBlock = (): JSX.Element => {
    const { tenantConfig } = useFragmentContext<FooterContext>();
    const intl = useIntl();
    const { linkBlock } = tenantConfig;

    return (
        <div className="container p-2x pb-0 d-none d-sm-flex flex-sm-wrap justify-content-sm-between">
            {linkBlock.map(({ list, title }) => (
                <div key={`blockId-${list[0]?.messageId}`} className="mb-2x mr-1x">
                    <span className="theme-text-subtitle-text d-block mb-1-5x">
                        {title ? intl.formatMessage(messages[title]) : '\u00A0'}
                    </span>
                    <ul className={classNames(styles.linkGroup, 'p-0 text-nowrap')}>
                        {list.map(linkItem => {
                            return (
                                <li key={linkItem.messageId}>
                                    <HelpLink
                                        href={linkItem.url}
                                        message={messages[linkItem.messageId]}
                                    />
                                </li>
                            );
                        })}
                    </ul>
                </div>
            ))}
        </div>
    );
};

export { LinksBlock };
