import axios from 'axios';

import { createFragment } from '@jsmdg/react-fragment-scripts/fragment';
import { type RootComponentProps } from '../shared/types/rootComponentProps';
import { Footer } from './components/Footer';
import { addPaybackScridToCart } from './services/addPaybackScridToCart';
import { getTenantConfig } from './tenantConfigs/tenantConfig';
import { type FooterContext } from './types';

async function initDependencies({ baseUrl }: { baseUrl: string }): Promise<void> {
    axios.defaults.baseURL = baseUrl;
}

const { Fragment, init } = createFragment<RootComponentProps, FooterContext>(
    Footer,
    ({ locale, tenant, rootComponentProps }) => {
        return {
            tenant,
            locale,
            ...rootComponentProps,
            tenantConfig: {
                ...getTenantConfig(tenant, rootComponentProps.sharedData),
                ...rootComponentProps.sharedData,
            },
            featureFlagsVariation: rootComponentProps.featureFlagsVariation,
            addPaybackScridToCart,
        };
    },
    initDependencies,
);

export { Fragment, init };
