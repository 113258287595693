import { useEffect } from 'react';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { type FooterContext } from '../types';

export const usePaybackScrid = () => {
    const { addPaybackScridToCart, isPaybackScridProcessingEnabled } =
        useFragmentContext<FooterContext>();

    useEffect(() => {
        if (!isPaybackScridProcessingEnabled) {
            return;
        }

        (async () => {
            const url = new URL(window.location.href);
            const scrid = url.searchParams.get('scrid');

            if (scrid) {
                try {
                    await addPaybackScridToCart(scrid);
                } catch {
                    /* Scrid could not be added to the cart so the customer needs to enter his payback number manually on the cart page */
                } finally {
                    url.searchParams.delete('scrid');

                    window.history.replaceState({}, '', url.toString());
                }
            }
        })();
    }, []);
};
