import { Brand, CountryCode, Tenant, type TenantEnum } from '@jsmdg/tenancy';

type TenantCondition = {
    isMydays: boolean;
    isDE: boolean;
};

export function getTenantCondition(tenant: TenantEnum): TenantCondition {
    const tenantInstance = new Tenant(tenant);
    const isMydays = tenantInstance.belongsTo(Brand.Mydays);
    const isDE = tenantInstance.belongsTo(CountryCode.Germany);
    return { isMydays, isDE };
}
