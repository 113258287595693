import axios from 'axios';
import { BroadcastChannel } from 'broadcast-channel';

import { type Cart } from '../../shared/types/cart';

export const addPaybackScridToCart = async (scrid: string): Promise<void> => {
    const response = await axios.post<Cart>('/api/cart/add-payback-scrid', {
        scrid,
    });

    const syncCartBroadcastChannel = new BroadcastChannel('sync-cart-broadcast-channel', {
        webWorkerSupport: false,
    });

    // eslint-disable-next-line unicorn/require-post-message-target-origin
    await syncCartBroadcastChannel.postMessage({ cartVersion: response.data.cartVersion });
    await syncCartBroadcastChannel.close();
};
