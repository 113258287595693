export enum FooterLinkMessageId {
    Faq = 'faq',
    Contact = 'contact',
    PaymentMethods = 'paymentMethods',
    AboutUs = 'aboutUs',
    Jobs = 'jobs',
    JobsAndCareers = 'jobsAndCareers',
    AffiliateProgram = 'affiliateProgram',
    Press = 'press',
    TermsAndConditions = 'termsAndConditions',
    Privacy = 'privacy',
    TermsOfUse = 'termsOfUse',
    OnlineMarketplace = 'onlineMarketplace',
    LegalNotice = 'legalNotice',
    Shipping = 'shipping',
    Payback = 'payback',
    CookieSettings = 'cookieSettings',
    Compliance = 'compliance',
    TrustedShops = 'trustedShops',
    Magazin = 'magazin',
}
